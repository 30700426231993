.About {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #000000;
    color: white;
    text-align: center;
    margin: 0 auto;
}

.about-p {
    padding-top: 100px;
    background-color: #000000;
}

.pooluslogo img {
    width: 120px;
}

.pooluslogo1 img {
    width: 200px;
}

.pooluslogo2 {
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 5px;
    margin-top: 10px;
    color: #6bc6dd;
}

.mar {
    margin-top: 40px;
}

.logoX {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    position: relative;
    z-index: 1;
}

.sku-likelion {
    position: absolute;
    z-index: 0;
    left: 35px;
}

.sku-renew {
    position: absolute;
    right: 35px;
}

.logoX img {
    display: inline-block;
    margin: 25px 25px 12px 25px;
}

.likelion-logo {
    width: 70px;
    height: auto;
}

.renew-logo {
    width: 85px;
    height: auto;
}

.logoX span {
    font-size: 40px;
}

.logoX div {
    display: flex;
    flex-direction: column;
}

.logoX div span {
    font-size: 13px;
}

.collaboration {
    font-size: 12px;
    margin-top: 15px;
}

.people {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #000000;
    color: white;
    text-align: center;
    margin: 0 auto;
}

.smlogo {
    width: 45px;
    height: auto;
    margin: 50px 10px 5px 10px;
}

.smlogo1 {
    width: 50px;
    height: auto;
    margin: 50px 10px 5px 10px;
}

.gridpeople {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.llblue {
    color: #7193C3;
}

.renewyellow {
    color: #B69414;
}


/* 개인 커스텀 */

.pbox {
    /* width: 60px;
    height: 90px;
    font-size: 5px; */
    width: 120px;
    height: 170px;
    background-color: #565656;
    border-radius: 10px;
    /* display: flex;
    flex-direction: column; */
}

.Pimg {
    height: 70px;
    width: auto;
    margin-bottom: 11px;
}

.pbox {
    padding: 5px;
}

.pbox span {
    display: block;
}

.Pinfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 3px;
    align-items: center;
}

.Pinfo img {
    height: 18px;
    width: auto;
    margin-right: 5px;
}

.Pinfo span {
    font-size: 5px;
}

.Pdepart {
    margin: 0;
    font-size: 9px;
    color: #f3cf4e;
}

.Pposi {
    font-size: 9px;
    margin: 0;
}

hr {
    margin: 0.2rem;
}

.Prole {
    margin-top: 5px;
    font-size: 11px;
}

.renew {
    margin-bottom: 50px;
}

.renew>.pertitle>.renewyellow {
    font-size: 10px;
    margin-top: 3px;
    /* margin-bottom: 15px; */
}

.likelion>.pertitle>.llblue {
    font-size: 10px;
    margin-top: 3px;
    /* margin-bottom: 15px; */
}
.instagram {
    margin-bottom: 15px;
}
.instagram > a{
    color: #cecece;
    font-size: 12px;
    text-decoration: none !important;
}