/* 캐러잴 속성들 */

.carousel-control-prev-icon {
    display: none;
}

.carousel-control-next-icon {
    display: none;
}

.carousel-inner {
    overflow: visible;
}

.carousel-item {
    transition: transform .7s cubic-bezier(0.29, 0.92, 1, 1)
}


/* 캐러샐  */


/* day1, day2 타이틀 */

.daytitle {
    margin: 20px;
}


/* 메인1 */

.Lineuptitle {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: black;
}

.Lineuptitle img {
    width: 330px;
    height: auto;
}

.clickparent {
    background: none;
    border: none;
    position: relative;
    z-index: 0;
}

.click {
    position: absolute;
    right: -100px;
    bottom: -100px;
    z-index: 1;
}

.Day {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dayArtist {
    position: relative;
}

.dayArtist button {
    background: none;
    border: none;
    position: absolute;
}

.dayArtist button:first-child {
    position: absolute;
    top: 22px;
    left: -40px;
    margin-top: 30px;
}

.dayArtist button:last-child {
    position: absolute;
    top: 22px;
    right: -40px;
}

.fixtree1 {
    position: absolute;
    left: 0;
    bottom: 0;
}

.fixtree2 {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -3;
}

.fixlogo {
    width: 100px;
    position: absolute;
    left: 10px;
    top: 10px;
}

.dayssss {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dayssss img {
    margin-bottom: 30px;
}

.waterimg img {
    min-width: 415px;
}

.DayN {
    width: 263px;
    height: 94px;
}

@font-face {
    font-family: 'LOTTERIADDAG';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2302@1.0/LOTTERIADDAG.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

.LineupSlide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dayparent {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
}

.dayss {
    font-family: 'LOTTERIADDAG' !important;
    font-size: 30px;
    color: rgb(0, 0, 0, 0.8);
}

.activeday {
    font-size: 50px;
    color: black;
}

.artistcarousel {
    width: 300px;
    position: relative;
    margin: 0 auto;
}


/* 홀수 왼쪽 */

.artist {
    width: 300px;
    height: 350px;
    position: relative;
    margin: 0 auto;
    text-align: left;
}

.artist img:first-child {
    width: 230px;
}

.artist img:nth-child(2) {
    width: 180px;
    position: absolute;
    bottom: 132px;
    left: 132px;
}

.artist img:last-child {
    width: 100px;
    position: absolute;
    bottom: 96px;
    right: -9px;
}


/* 짝수 오른쪽 */

.artist2 {
    width: 300px;
    height: 350px;
    position: relative;
    margin: 0 auto;
    text-align: right;
}

.artist2 img:first-child {
    width: 230px;
}

.artist2 img:nth-child(2) {
    width: 180px;
    position: absolute;
    bottom: 108px;
    left: -15px;
}

.artist2 img:last-child {
    width: 100px;
    position: absolute;
    bottom: 78px;
    left: -9px;
}

.bottomtime {
    bottom: 65px !important;
}

.lineupview {
    margin-top: 180px;
}

.dayss {
    padding: 5px;
    padding-bottom: 10px;
    background: none;
    font-size: 20px;
    color: rgb(255, 255, 255, 0.5);
    border: none;
    border-bottom: 5px solid rgb(30, 64, 74, 0.5);
    width: 120px;
    text-align: center;
}

.dayss:first-child {
    margin-right: 20px;
}

.activeartist {
    padding: 5px;
    padding-bottom: 10px;
    background: none;
    font-size: 22px !important;
    color: white !important;
    border: none;
    border-bottom: 5px solid #1e404a !important;
    width: 120px;
    text-align: center;
}

.activeartist:first-child {
    margin-right: 20px;
}