    .Studentdue {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
    /* 공지사항 메인 */
    
    .InfoMain {
        font-family: 'SBAggro1';
    }
    
    .InfoTitle {
        margin-top: 30px;
    }
    
    .InfoTitle span {
        font-size: 65px;
        letter-spacing: 3px;
        color: #FFC7C7;
        font-family: 'SBAggro3'
    }
    
    .infoimg {
        margin: 15px;
    }
    
    .infoimg img {
        width: 140px;
    }
    
    .InfoMain1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .Infomenu {
        padding: 15px;
    }
    
    .Infomenu button {
        width: 230px;
        height: 60px;
        background-color: #494949;
        border: none;
        border-radius: 10px;
        font-size: 20px;
        color: #ffc7c7;
        letter-spacing: 2px;
        font-family: 'SBAggro2'
    }
    /* 학생회비 납부 혜택 */
    
    .duetitle {
        font-size: 32px;
        letter-spacing: 3px;
        color: #FFC7C7;
        font-family: 'SBAggro3'
    }
    
    .duetitle a {
        color: inherit;
        font-family: inherit;
        text-decoration: none;
    }
    /* 납부자 */
    
    .yesduesub {
        overflow: hidden;
        width: 300px;
        margin-bottom: 20px;
    }
    
    .yesduesub img {
        width: 300px;
        height: 30px;
    }
    
    .yesduesub p {
        text-align: left;
        margin-left: 10px;
    }
    
    .yesduesub p span {
        color: #FFC7C7;
        font-size: 20px;
        font-weight: bold;
        font-style: italic;
        display: inline-block;
        margin-bottom: 10px;
    }
    /* 미납부자 */
    
    .noduesub {
        overflow: hidden;
        width: 300px;
    }
    
    .noduesub p {
        text-align: right;
        margin-right: 10px;
    }
    
    .noduesub p span {
        color: #FFC7C7;
        font-size: 20px;
        font-weight: bold;
        font-style: italic;
        display: inline-block;
        margin-bottom: 10px;
    }
    
    .pink {
        color: #ffc7c7;
    }
    
    .green {
        color: #287e3a;
        font-weight: boldw;
    }
    
    .alt {
        font-style: italic;
        text-decoration: underline;
    }
    
    .dueAlert {
        margin-top: 10px;
    }
    /* 리뉴 캐쉬 */
    
    .RenewCash {
        display: flex;
        flex-direction: column;
    }
    
    .cashalert {
        margin-top: 30px;
        margin-bottom: 50px;
    }
    
    .cashtitle {
        margin: 50px 0 20px 0;
        font-size: 42px;
        letter-spacing: 3px;
        color: #FFC7C7;
        font-family: 'SBAggro3'
    }
    
    .cashtitle a {
        color: inherit;
        font-family: inherit;
        text-decoration: none;
    }
    
    .cashalert span {
        font-size: 14px;
        display: inline-block;
        margin-bottom: 10px;
    }
    
    .cardflex {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
    .cardflex div {
        width: 130px;
    }
    
    .cardflex img {
        width: 120px;
    }
    
    .cashsub {
        background-color: #191919;
        margin: 0 auto;
        padding: 20px;
        border-radius: 20px;
    }
    
    .cashsub p {
        margin: 10px 0;
    }
    
    .cashsub {
        font-size: 16px;
    }