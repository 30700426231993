/* .display-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 390px;
    background-color: #000000;
    min-height: 100vh;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(255,255,255,0.75);
    -moz-box-shadow: 0px 0px 15px 0px rgba(255,255,255,0.75);
    box-shadow: 0px 0px 15px 0px rgba(255,255,255,0.75);
    overflow-x: hidden;
    overflow-y: hidden;
} */

.main {
    position: relative;
    height: 100vh;
    /* 영상의 높이에 맞게 조정하세요 */
}

.wayto-water-island {
    position: absolute;
    z-index: 1;
    bottom: 0;
    margin-bottom: 35px;
}

.wayto-water-island p {
    font-size: 12px;
    color: #ffffff;
    text-shadow: 2px 2px 5px black;
    margin-bottom: 5px;
}

.wayto-water-island img {
    width: auto;
    height: 40px;
    filter: drop-shadow(2px 2px 5px #000000);
}

.main video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 1;
    object-fit: cover;
}

.glitch {
    position: relative;
    font-size: 100px;
    font-weight: 400;
    line-height: 1.2;
    color: #fff;
    text-shadow: 2px 2px 5px black;
    letter-spacing: 5px;
    z-index: 1;
}

.glitch:before {
    content: attr(data-glitch);
    position: absolute;
    top: 0;
    left: -2px;
    width: 100%;
    color: #fff;
    text-shadow: 2px 2px 5px black;
    background-color: rgba(0, 0, 0, 0);
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-before 3s infinite linear alternate-reverse;
}

.glitch:after {
    content: attr(data-glitch);
    position: absolute;
    top: 0;
    left: 2px;
    width: 100%;
    color: #fff;
    text-shadow: 2px 2px 5px black;
    background-color: rgba(0, 0, 0, 0);
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-after 2s infinite linear alternate-reverse;
}

@keyframes noise-before {
    0% {
        clip: rect(61px, 9999px, 52px, 0);
    }
    5% {
        clip: rect(33px, 9999px, 144px, 0);
    }
    10% {
        clip: rect(121px, 9999px, 115px, 0);
    }
    15% {
        clip: rect(144px, 9999px, 162px, 0);
    }
    20% {
        clip: rect(62px, 9999px, 180px, 0);
    }
    25% {
        clip: rect(34px, 9999px, 42px, 0);
    }
    30% {
        clip: rect(147px, 9999px, 179px, 0);
    }
    35% {
        clip: rect(99px, 9999px, 63px, 0);
    }
    40% {
        clip: rect(188px, 9999px, 122px, 0);
    }
    45% {
        clip: rect(154px, 9999px, 14px, 0);
    }
    50% {
        clip: rect(63px, 9999px, 37px, 0);
    }
    55% {
        clip: rect(161px, 9999px, 147px, 0);
    }
    60% {
        clip: rect(109px, 9999px, 175px, 0);
    }
    65% {
        clip: rect(157px, 9999px, 88px, 0);
    }
    70% {
        clip: rect(173px, 9999px, 131px, 0);
    }
    75% {
        clip: rect(62px, 9999px, 70px, 0);
    }
    80% {
        clip: rect(24px, 9999px, 153px, 0);
    }
    85% {
        clip: rect(138px, 9999px, 40px, 0);
    }
    90% {
        clip: rect(79px, 9999px, 136px, 0);
    }
    95% {
        clip: rect(25px, 9999px, 34px, 0);
    }
    100% {
        clip: rect(173px, 9999px, 166px, 0);
    }
}

@keyframes noise-after {
    0% {
        clip: rect(26px, 9999px, 33px, 0);
    }
    5% {
        clip: rect(140px, 9999px, 198px, 0);
    }
    10% {
        clip: rect(184px, 9999px, 89px, 0);
    }
    15% {
        clip: rect(121px, 9999px, 6px, 0);
    }
    20% {
        clip: rect(181px, 9999px, 99px, 0);
    }
    25% {
        clip: rect(154px, 9999px, 133px, 0);
    }
    30% {
        clip: rect(134px, 9999px, 169px, 0);
    }
    35% {
        clip: rect(26px, 9999px, 187px, 0);
    }
    40% {
        clip: rect(147px, 9999px, 137px, 0);
    }
    45% {
        clip: rect(31px, 9999px, 52px, 0);
    }
    50% {
        clip: rect(191px, 9999px, 109px, 0);
    }
    55% {
        clip: rect(74px, 9999px, 54px, 0);
    }
    60% {
        clip: rect(145px, 9999px, 75px, 0);
    }
    65% {
        clip: rect(153px, 9999px, 198px, 0);
    }
    70% {
        clip: rect(99px, 9999px, 136px, 0);
    }
    75% {
        clip: rect(118px, 9999px, 192px, 0);
    }
    80% {
        clip: rect(1px, 9999px, 83px, 0);
    }
    85% {
        clip: rect(145px, 9999px, 98px, 0);
    }
    90% {
        clip: rect(121px, 9999px, 154px, 0);
    }
    95% {
        clip: rect(156px, 9999px, 44px, 0);
    }
    100% {
        clip: rect(67px, 9999px, 122px, 0);
    }
}

.sub-title {
    text-shadow: 2px 2px 5px black;
    position: relative;
    z-index: 1;
}

.btn-main {
    position: relative;
    z-index: 1;
    text-decoration-line: none;
    filter: drop-shadow(0px 0px 8px #ffffff);
}

.btn-main-waterisland-in {
    color: #6bc6dd;
    background: #000000;
    width: 98%;
    height: 100%;
    clip-path: polygon(9% 0, 100% 0, 100% 66%, 91% 100%, 0 100%, 0 34%);
    transition: 0.3s;
}

.btn-main-waterisland-out {
    position: absolute;
    background: #6bc6dd;
    width: 100%;
    height: 110%;
    z-index: -1;
    clip-path: polygon(9% 0, 100% 0, 100% 66%, 91% 100%, 0 100%, 0 34%);
}

.btn-main-waterisland-in:hover {
    color: #ffffff;
    background-color: #55d6f6;
    width: 98%;
    height: 100%;
    clip-path: polygon(9% 0, 100% 0, 100% 66%, 91% 100%, 0 100%, 0 34%);
    transition: 0.3s;
}

.btn-main-romanticisland-in {
    color: #c62b6b;
    background: #000000;
    width: 98%;
    height: 100%;
    clip-path: polygon(9% 0, 100% 0, 100% 66%, 91% 100%, 0 100%, 0 34%);
    transition: 0.3s;
}

.btn-main-romanticisland-out {
    position: absolute;
    background: #c62b6b;
    width: 100%;
    height: 110%;
    z-index: -1;
    clip-path: polygon(9% 0, 100% 0, 100% 66%, 91% 100%, 0 100%, 0 34%);
}

.btn-main-romanticisland-in:hover {
    color: #ffffff;
    background-color: #e43a81;
    width: 98%;
    height: 100%;
    clip-path: polygon(9% 0, 100% 0, 100% 66%, 91% 100%, 0 100%, 0 34%);
    transition: 0.3s;
}

.btn-main-gardenisland-in {
    color: #287e3a;
    background: #000000;
    width: 98%;
    height: 100%;
    clip-path: polygon(9% 0, 100% 0, 100% 66%, 91% 100%, 0 100%, 0 34%);
    transition: 0.3s;
}

.btn-main-gardenisland-out {
    position: absolute;
    background: #287e3a;
    width: 100%;
    height: 110%;
    z-index: -1;
    clip-path: polygon(9% 0, 100% 0, 100% 66%, 91% 100%, 0 100%, 0 34%);
}

.btn-main-gardenisland-in:hover {
    color: #ffffff;
    background-color: #29a844;
    width: 98%;
    height: 100%;
    clip-path: polygon(9% 0, 100% 0, 100% 66%, 91% 100%, 0 100%, 0 34%);
    transition: 0.3s;
}

.waterisland-section {
    background-color: #000000;
    position: relative;
    min-height: 100vh;
    padding-top: 100px;
    z-index: 0;
}

.waterisland-section .island-title {
    font-size: 30px;
    color: black;
    margin-bottom: -30px;
    -webkit-text-stroke: 1px #6bc6dd;
}

.waterisland-section .poolus-title {
    font-size: 100px;
    color: #6bc6dd;
}

.waterisland-section .section-description {
    color: #6bc6dd;
    font-size: 15px;
    text-shadow: 2px 2px 5px black;
}


/* WaterIsland.css import 안했는데 이거 왜 되는거야? */

.main-waterIslandBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}


/* WaterIsland.css에 있는 속성 몇가지 무력화 */

.main-waterIslandBtn button {
    padding: 10px !important;
    margin-bottom: 0 !important;
    background-color: #6CC7DF;
    border-radius: 10px !important;
    border: none;
    font-size: 20px;
    font-family: SBAggro2;
    width: 200px;
    color: black;
    filter: drop-shadow(2px 2px 5px #000000);
}

.waterisland-img {
    position: absolute;
    z-index: -1;
    bottom: 0;
}

.wayto-romantic-island {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 20px;
}

.wayto-romantic-island p {
    font-size: 12px;
    font-weight: 900;
    color: #000000;
    /* text-shadow: 2px 2px 5px black; */
    margin-bottom: 5px;
}

.wayto-romantic-island img {
    width: auto;
    height: 40px;
    filter: drop-shadow(2px 2px 5px #000000);
}

.waterisland-title {
    position: relative;
}

.wave-line-1 {
    position: absolute;
    width: 100px;
    height: auto;
    top: 10px;
    right: -65px;
    z-index: -1;
}

.wave-line-2 {
    position: absolute;
    width: 100px;
    height: auto;
    bottom: 50px;
    left: -65px;
    z-index: -1;
}

.romanticisland-section {
    position: relative;
    min-height: 100vh;
    padding-top: 100px;
    z-index: 0;
    background-color: #9fd5f4;
}

.romanticisland-title {
    position: relative;
}

.romanticisland-section .island-title {
    font-size: 30px;
    font-weight: 900;
    color: transparent;
    margin-bottom: -30px;
    -webkit-text-stroke: 1px #c62b6b;
}

.romanticisland-section .poolus-title {
    font-size: 100px;
    color: #c62b6b;
    margin-bottom: 0;
}

.spring {
    width: auto;
    height: 50px;
}

.hashtag span {
    font-size: 12px;
    color: #9fd5f4;
    background-color: #c62b6b;
    /* border-radius: 5px; */
    /* padding: 2px 7px; */
}

.romanticisland-section .section-description {
    margin-top: 30px;
    font-size: 15px;
    color: #c62b6b;
}

.cloud-1 {
    position: absolute;
    width: 155px;
    height: auto;
    top: -30px;
    left: 0;
    z-index: -1;
}

.cloud-2 {
    position: absolute;
    width: 155px;
    height: auto;
    top: 150px;
    right: -35px;
    z-index: -1;
}

.cloud-3 {
    position: absolute;
    width: 155px;
    height: auto;
    top: 350px;
    left: -25px;
    z-index: -1;
}

.romanticisland-img {
    position: absolute;
    z-index: -1;
    bottom: 0;
}

.wayto-garden-island {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 20px;
}

.wayto-garden-island p {
    font-size: 12px;
    font-weight: 900;
    color: #c62b6b;
    margin-bottom: 5px;
}

.wayto-garden-island img {
    width: auto;
    height: 40px;
}

.heart-group {
    position: relative;
}

.heart-1 {
    position: absolute;
    width: 100px;
    height: auto;
    left: 50px;
}

.heart-2 {
    position: absolute;
    width: 50px;
    height: auto;
    right: 100px;
}

.heart-3 {
    position: absolute;
    width: 50px;
    height: auto;
    right: 55px;
    top: 40px;
}

.gardenisland-section {
    position: relative;
    min-height: 100vh;
    padding-top: 100px;
    z-index: 0;
    background-color: #fef5e8;
}

.gardenisland-title {
    position: relative;
}

.gardenisland-section .island-title {
    font-size: 30px;
    font-weight: 900;
    color: transparent;
    margin-bottom: -30px;
    -webkit-text-stroke: 1px #287e3a;
}

.gardenisland-section .poolus-title {
    font-size: 100px;
    color: #287e3a;
    margin-bottom: 0;
}

.gardenisland-section .section-description {
    margin-top: 30px;
    font-size: 15px;
    color: #287e3a;
    text-shadow: 0px 0px 10px rgb(255, 255, 255);
}

.garden-cloud-1 {
    position: absolute;
    width: 155px;
    height: auto;
    top: 30px;
    left: 0;
    z-index: -1;
}

.garden-cloud-2 {
    position: absolute;
    width: 155px;
    height: auto;
    top: 150px;
    right: -35px;
    z-index: -1;
}

.garden-cloud-3 {
    position: absolute;
    width: 155px;
    height: auto;
    top: 265px;
    left: -25px;
    z-index: 1;
}

.garden-cloud-4 {
    position: absolute;
    width: 100px;
    height: auto;
    top: 440px;
    right: 10px;
    z-index: -1;
}

.gardenisland-img {
    position: absolute;
    z-index: -1;
    bottom: 0;
}

.leaves {
    width: auto;
    height: 70px;
}

.gardenIslandBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}


/* WaterIsland.css에 있는 속성 몇가지 무력화 */

.gardenIslandBtn button {
    padding: 10px;
    margin-bottom: 0;
    background-color: #287e3a;
    border-radius: 10px;
    border: none;
    font-size: 20px;
    font-family: SBAggro2;
    width: 200px;
    color: #fef5e8;
}