.TimeTable {
    background-color: black;
    width: 100%;
    max-width: 100%;
}


/* titleimage */

.HeartImg {
    padding: 50px;
}


/* button */

.dayBtns {
    display: flex;
    justify-content: center;
    font-family: 'SBAggro2';
}

.active-day {
    color: #6CC7DF !important;
    font-weight: bold;
    font-size: 24px !important;
    opacity: 1;
    transition: opacity 0.5s ease;
}

.non-act {
    opacity: 0.6;
}

.dayBtn {
    background-color: inherit;
    border: none;
    color: white;
    font-size: 23px;
}


/* 부스 & 공연 css */

.boothNstage {
    display: flex;
    justify-content: space-between;
    width: 360px;
    margin: 0 auto;
    margin-bottom: 50px;
}

.boothNstage>img {
    height: 1100px;
}


/* 부스 */

.boothBox {
    margin-left: 20px;
    width: 150px;
    padding: 10px 10px 10px 0px;
    margin-bottom: 30px;
    text-align: right;
    position: relative;
    word-break: keep-all;
    box-shadow: -2px 2px 4px rgb(108, 199, 223, 0.5);
}

.boothBox:last-child {
    margin-top: 400px;
}


/* 시간 */

.boothBox>div:first-child {
    font-family: 'SBAggro1';
    margin-bottom: 6px;
    font-size: 12px;
}


/* 타이틀 */

.boothBox>div:nth-child(2) {
    font-size: 18px;
    font-weight: bold;
}


/* 장소 */

.boothBox>div:last-child {
    font-size: 13px;
    font-family: 'SBAggro1';
}

.blueCircle {
    position: absolute;
    top: 12px;
    right: -16.7px;
}


/* 공연 */

.bothbooth {
    font-size: 30px;
    font-family: 'SBAggro2';
    padding: 10px 20px;
    width: 100px;
}

.boothTitle {
    text-align: right;
    border-bottom: 5px solid rgb(108, 199, 223);
    margin-bottom: 30px;
    margin-left: 70px;
}

.breakword {
    word-break: keep-all;
}

.stageTitle {
    font-family: 'SBAggro2';
    text-align: left;
    border-bottom: 5px solid #6CDD9D;
    margin-right: 60px;
    margin-bottom: 60px;
}

stageTitle .stagemt {
    margin-top: 30px;
    height: 100vh;
}

.stageBox {
    margin-right: 20px;
    width: 150px;
    padding: 10px 0 10px 10px;
    margin-bottom: 30px;
    text-align: left;
    position: relative;
    box-shadow: 2px 2px 4px rgb(108 221 157 / 50%);
}

.stageBox>div:first-child {
    font-family: 'SBAggro1';
    margin-bottom: 6px;
    font-size: 12px;
}

.stageBox>div:nth-child(2),
.TTtitle {
    font-size: 17px;
    font-weight: bold;
    text-align: left;
}

.stageBox>div:last-child,
.TTtime {
    font-size: 13px;
    font-family: 'SBAggro1';
    text-align: left;
}

.TTbtn {
    background: inherit;
    color: white;
    padding: 0;
    border: none;
}

.stageBox a {
    text-decoration: none;
    color: white;
}

.greenCircle {
    position: absolute;
    top: 12px;
    left: -16.7px;
}

.tthr {
    margin: 1rem;
    margin-top: 170px;
}

.zindex {
    z-index: auto;
}

.tooltip {
    --bs-tooltip-color: rgb(108 221 157);
}

.TTbtn-no-shadow {
    border: none;
}

.overlaysub {
    word-break: keep-all;
}

.tooltip-inner {
    z-index: 0;
}

.tooltip {
    --bs-tooltip-zindex: 0;
}