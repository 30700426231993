.Mainmap {
    background-image: url('./mapbackground.png');
    background-size: cover;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mapmain img {
    padding: 0 15px;
    margin: 0 auto;
}

.mapmenu img {
    padding: 0px 15px 30px 15px;
    margin: 0 auto;
}

.maplogo {
    width: 300px;
    height: auto;
    margin: 100px auto 0px auto;
}