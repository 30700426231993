.good {
    color: white;
    max-width: 415px;
    text-align: center;
    margin: 0 auto;
    min-height: 100vh;
}

.vhHeight {
    height: 100vh;
}

.percentHeight {
    height: 100%;
}

.flexend {
    align-items: flex-end !important;
}


/* 모바일 스타일 */

@media (max-width: 767px) {}


/* 태블릿 스타일 */

@media (min-width: 768px) and (max-width: 1023px) {}


/* PC 스타일 */

@media (min-width: 1024px) {}