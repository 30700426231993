.modal-dialog {
    height: 100%;
    display: flex;
    align-items: center;
}

.modal-content {
    background-color: #FEF5E8;
}

.modal-title {
    font-family: 'SBAggro3';
}

.modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px;
    border: 5px solid #D9AE8C;
    padding: 30px;
}

.modal-body section {
    width: 100%;
    max-height: 70vh;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

.modal-body section::-webkit-scrollbar {
    width: 5px;
    /* 스크롤바의 너비 */
}

.modal-body section::-webkit-scrollbar-thumb {
    background-color: rgb(223, 179, 147, 0.5);
    /* 스크롤바 썸네일 색상을 투명으로 지정 */
}

.modal-body section div {
    margin-bottom: 10px;
    text-align: center;
}

.modal-body section div hr {
    margin: 0.5rem;
    text-align: center;
}

.modal-body section div:last-child {
    margin-bottom: 0;
    text-align: center;
}

.modal-body section div:last-child hr {
    display: none;
}

.menuname {
    font-family: 'SBAggro3';
    font-size: 18px;
}

.menuprice {
    font-family: 'SBAggro2';
    font-size: 14px;
}