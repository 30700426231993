.waterbtnsparent {
    position: relative;
}

.waterbtns {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: absolute;
    top: 0;
    width: 100%;
}

.wslidebtn:first-child {
    margin-right: 30px;
}

.wslidebtn {
    font-family: SBAggro2;
    padding: 5px;
    padding-bottom: 10px;
    background: none;
    font-size: 18px;
    color: rgb(255, 255, 255, 0.5);
    border: none;
    border-bottom: 5px solid rgb(108, 199, 223, 0.5);
    width: 120px;
}

.activebtn {
    font-family: SBAggro2;
    padding: 5px;
    padding-bottom: 10px;
    background: none;
    font-size: 20px !important;
    color: white !important;
    border: none;
    border-bottom: 5px solid #6CC7DF !important;
    width: 120px;
}

.attrflex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.attrbtns {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.attrbtns button {
    width: 130px;
    background-color: rgb(108, 199, 223, 0.3);
    border-radius: 10px;
    border: none;
    padding: 5px;
    margin: 0 10px;
}

.attrbtns a {
    text-decoration: none;
    color: white;
}

.AttrImg img {
    width: 260px;
}

.goodsflex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.goodsbtn button {
    width: 180px;
    background-color: rgb(108, 199, 223, 0.3);
    border-radius: 10px;
    border: none;
    padding: 5px;
}

.goodsbtn a {
    text-decoration: none;
    color: white;
}

.AttrComp {
    margin-top: 140px;
}

.accordion {
    --bs-accordion-bg: inherit;
    --bs-accordion-border-width: 0;
    --bs-accordion-btn-padding-x: 0;
    --bs-accordion-btn-padding-y: 0;
    --bs-accordion-btn-color: #6CC7DF;
}

.finisht {
    margin-right: 5px;
}

.accordion-header {
    margin-bottom: 5px;
    border: none;
    padding: 2px;
    border-radius: none;
}

.accordion-button::after {
    color: #6cc7df;
    margin-left: 10px;
}

.accordion-button:not(.collapsed) {
    color: #6CC7DF;
    background-color: inherit;
}

.accordion-body {
    padding: 10px;
    color: white;
    background-color: #171717;
    border-radius: 10px;
    width: 200px;
    margin-bottom: 10px;
}

.accordion-body span {
    color: #6CC7DF;
}

.accordion-button:not(.collapsed)::after {
    background-image: none;
}