@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@font-face {
    font-family: 'GongGothicMedium';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-10@1.0/GongGothicMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SBAggroB';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SBAggroB.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.isamanroo-font {
    font-family: 'GongGothicMedium', sans-serif;
}

.anton-font {
    font-family: 'Anton', sans-serif;
}

.sbaggro-font {
    font-family: 'SBAggroB', sans-serif;
}

.navmargin {
    margin-top: 75px !important;
}


/* body {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100%;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
} */

.App {
    background-color: #0f0f0f;
}

.diao {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1;
    padding: 0;
}

.infodiao img {
    min-width: 415px;
    height: 81px !important;
}

.gardendiao {
    background-color: #FEF5E8;
}

.gardendiao img {
    width: 100%;
    height: 44px !important;
}

.diao img {
    height: 140px;
    width: 370px;
}

@font-face {
    font-family: 'SBAggro1';
    font-weight: 300;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroLight.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroLight.eot?#iefix') format('embedded-opentype'), url('https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroLight.woff2') format('woff2'), url('https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroLight.woff') format('woff'), url('https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroLight.ttf') format("truetype");
    font-display: swap;
}

@font-face {
    font-family: 'SBAggro2';
    font-weight: 500;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroMedium.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroMedium.eot?#iefix') format('embedded-opentype'), url('https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroMedium.woff2') format('woff2'), url('https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroMedium.woff') format('woff'), url('https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroMedium.ttf') format("truetype");
    font-display: swap;
}

@font-face {
    font-family: 'SBAggro3';
    font-weight: 700;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroBold.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroBold.eot?#iefix') format('embedded-opentype'), url('https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroBold.woff2') format('woff2'), url('https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroBold.woff') format('woff'), url('https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroBold.ttf') format("truetype");
    font-display: swap;
}

* {
    font-family: 'SBAggro1';
}

.bgb {
    background-color: black;
    /* height: 100vh;
    width: 375px; */
}

.display-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 415px;
    min-height: 100vh;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.75);
    -moz-box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.75);
    box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.75);
    overflow-x: hidden;
    /* overflow-y: hidden; */
}


/* LOGO 폰트
font-family: 'Anton', sans-serif; */


/* 이사만루 */

@font-face {
    font-family: '24manro';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-10@1.0/GongGothicMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}