.GardenIsland {
    color: #287E3A;
    font-family: 'SBAggro2';
}

.gardenlogo {
    margin-top: 40px;
}

.gardenlogo img {
    width: 307px;
}

.searchtext {
    margin-top: 15px;
    font-size: 15px;
    font-family: 'SBAggro2';
}

.gardenLocation {
    border-radius: 15px;
    margin: 0 auto;
    width: 250px;
    padding: 10px;
    font-size: 18px;
    margin: 15px auto;
    background-color: rgb(255, 255, 255, 0.6);
    position: relative;
}

.gardenLocation span {
    font-family: 'SBAggro2';
}

.mapinfo {
    font-family: 'SBAggro1' !important;
    font-size: 13px;
    font-weight: bold;
}

.gardenbooth img {
    width: 320px;
}

.search form {
    width: 200px;
    margin: 0 auto;
    margin-bottom: 20px;
    border: 2px solid #287e3a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    align-items: center;
}

.searchInput {
    width: 170px;
    background: none;
    border: none;
}

.fixbackground {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -2;
}

@media (min-width: 1024px) {
    .fixbackground {
        position: fixed;
        bottom: 0;
    }
    .wave {
        width: auto;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .fixbackground {
        position: fixed;
        bottom: 0;
    }
    .wave {
        width: auto;
    }
}

.GardenIsland {
    overflow-y: visible;
    margin-bottom: 150px;
    margin-top: 30px;
}

.boothmain {
    display: grid;
    align-content: center;
    justify-content: center;
}

.flexbooth {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    border-radius: 20px;
    margin-bottom: 15px;
    position: relative;
    background-color: white;
    padding-right: 15px;
}

.listImg img {
    width: 100px;
    overflow: hidden;
    border-radius: 20px 0 0 20px;
    margin-right: 15px;
}

.boothlist {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.boothtitle {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
}

.boothmenu {
    font-size: 12px;
    margin: 0;
    width: 170px;
    text-align: left;
    word-break: keep-all;
    font-family: 'SBAggro1';
}

.starbtn {
    border: none;
    background: none;
    position: absolute;
    right: 5px;
    bottom: 5px;
    color: #287E3A;
}

.garden-island-title .island-title {
    font-size: 30px;
    font-weight: 900;
    color: transparent;
    margin-bottom: -30px;
    -webkit-text-stroke: 1px #287e3a;
}

.garden-island-title .poolus-title {
    font-size: 100px;
    color: #287e3a;
    margin-bottom: 0;
}

.navmargingarden {
    margin-top: 120px;
}

.detailMap button {
    background: none;
    border: none;
    color: inherit;
    font-family: 'SBAggro2';
    box-shadow: 2px 2px 4px #287E3A;
    margin: 5px 15px;
    padding: 5px;
    font-size: 13px;
}

.greencolor {
    color: #6CDD9D;
}

.menuname {
    word-break: keep-all;
}