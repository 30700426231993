.fullbgB {
    background-color: black;
}

.fullbgBag {
    background-color: #FEF5E8;
}

.bgwaterImg {
    background-image: url('./mapbackground.png');
    background-size: cover;
}

.bginfo {
    background: linear-gradient(to bottom, black, #3C5F68, black);
}

.bgstar {
    background-image: url('../public/img/renewcash/greenstar.png');
}

.bginherit {
    background-color: inherit;
}