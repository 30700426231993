.WaterIsland {
    background-color: black;
    width: 100%;
    max-width: 100%;
}


/* 로고 */

.waterIslandLogo {
    padding: 30px 0;
}

.waterIslandLogo img {
    width: 300px;
}


/* 버튼 */

.waterIslandBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;
}

.waterIslandBtn button {
    padding: 15px;
    margin-bottom: 50px;
    background-color: #6CC7DF;
    border: 1px solid #6cc7df;
    border-radius: 20px;
    font-size: 20px;
    font-family: SBAggro2;
    width: 200px;
    color: black;
    z-index: 3;
}


/* 배경 */

.backgroundImg {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

.wave {
    width: 100%;
}

.tree {
    position: absolute;
    bottom: 0;
    width: 150px;
}


/* Attraction css */

.attractionBtn {
    padding: 15px;
    background-color: #6CC7DF;
    border: 1px solid #6cc7df;
    border-radius: 20px;
    font-size: 20px;
    position: relative;
    color: black;
    text-decoration: none;
    display: block;
    width: 200px;
    font-family: SBAggro3;
    margin: 0 auto;
}

.attractionBtn:hover {
    color: black;
}

.attractionBtn span {
    font-family: SBAggro2;
}

.arrowback {
    position: absolute;
    left: 20px;
    top: 18px;
}

.attrTitle {
    font-size: 35px;
    font-family: 'SBAggro2';
    letter-spacing: 5px;
    color: #6cc7df;
}


/* CarouselSize 안 요소들 */

.carousel-indicators {
    display: none;
    position: fixed;
}

.CarouselSize {
    width: 375px;
    height: 500px;
}

.CarouselSizeGoods {
    width: 280px;
    height: 500px;
    margin: 0 auto;
}

.attrSub {
    font-family: 'SBAggro2';
    text-align: left;
    padding-left: 40px;
    font-size: 15px;
    margin: 10px 0;
}

.attrSub p {
    margin-bottom: 0.5rem;
}

.blueFont {
    color: #6CC7DF;
}

.attrsubflex {
    display: flex;
    flex-direction: row;
    align-items: flex-start
}

.attrsubflex p {
    margin-left: 5px;
    margin-bottom: 3px;
}

.groundRule p {
    margin-bottom: 0.5rem;
    font-size: 13px;
}


/* goods */

.arrow {
    border: none;
    position: absolute;
    background-color: inherit;
    height: 100%;
}

.arrowflex {
    position: relative;
}

.arrow:first-child {
    left: -25px;
    bottom: 0;
}

.arrow:last-child {
    right: -25px;
    bottom: 0;
}


/* goods */

.goodsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 10px 0;
}

.goodsContainer div {
    width: 150px;
    height: 160px;
}

.goodsContainer div img {
    width: 120px;
}

.goodsContainer div p {
    margin-top: 10px;
}

.goodsContainer1 div {
    width: 150px;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
}

@media (min-width: 1024px) {
    .backgroundImg {
        position: fixed;
        bottom: 0;
    }
    .wave {
        width: auto;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .backgroundImg {
        position: fixed;
        bottom: 0;
    }
    .wave {
        width: auto;
    }
}

.Goods {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.griddd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 70vh;
}

.water-island-title .island-title {
    font-size: 30px;
    color: black;
    margin-bottom: -30px;
    -webkit-text-stroke: 1px #6bc6dd;
}

.water-island-title .poolus-title {
    font-size: 100px;
    color: #6bc6dd;
}